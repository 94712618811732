<template>
    <section class="content">
        <div class="row">
					<div class="col-md-12">
            <div class="info-box mb-3">
                <div class="info-box-content">
                  <div class="row mb-3">
                    <div class="col-md-12">
                        <div class="text-muted mb-1 font-13 text-uppercase flex">
                            Program
                        </div>
                        <h6 class="mb-1">
                        <span> {{ dataProgram.name }} </span>
                        </h6>
                    </div>
                    <div class="col-md-12">
                        <div class="text-muted mb-1 font-13 text-uppercase flex">
                            Deskripsi
                        </div>
                        <h6 class="mb-1">
                        <span> {{ dataProgram.deskripsi }} </span>
                        </h6>
                    </div>
                </div>
                <hr>
            <table class="table table-hover" ref="tbllistujian">
                <thead>
                <tr>
                    <th>TIPE</th>
                    <th>MAPEL</th>
                    <th>JUDUL</th>
                    <th>TANGGAL MULAI</th>
                    <th>TANGGAL SELESAI</th>
                    <th>KODE UJIAN</th>
                    <th>STATUS</th>
                </tr>
                </thead>
                <tbody></tbody>
            </table>
            <router-link
            :to="{ path: '/ujian-kelas' }"
            class="btn btn-secondary"
            >
            Kembali
            </router-link>
								</div>
						</div>
					</div>
        </div>
    </section>
</template>
<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import moment from "moment";

export default {
  name: "DetailUjianPerKelas",
  data() {
			return {
				dataProgram: {},
				errors: [],
				roles: "",
			};
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);

    this.loadDetail(this.$route.params.id);
  },
	methods: {
    loadDetail: function(id){
      authFetch("/akademik/ujian/per_kelas/" + id).then((res) => {
        res.json().then((json) => {
          this.dataProgram = json;
          console.log(this.dataProgram);
        });
      });
    },
	},
	mounted() {
    const e = this.$refs;
    let self = this;

		this.table = createTable(e.tbllistujian, {
      title: "List Ujian",
      roles: ["read"],
      ajax: "/akademik/ujian",
			paramData: function(d) {
        if (self.$route.params.id) {
            d.program_id = self.$route.params.id;
        } else {
            d.program_id = 0; //self.$route.params.id;
        }
      },
			selectedRowClass: "",
      columns: [
        { data: "ujian_type" },
        { data: "mapel" },
        { data: "ujian_title" },
        { data: "tanggal_mulai",
        render: function (data, type, row, meta) {
          return moment(data).format('DD/MM/YYYY')
        }, },
        { data: "tanggal_selesai",
        render: function (data, type, row, meta) {
          return moment(data).format('DD/MM/YYYY')
        }, },
        { data: "ujian_code" },
        { data: "status",
        render: function (data, type, row, meta) {
            return data > 0 ? '<span class="badge badge-success">Active</span>' : '<span class="badge badge-secondary">Not Active</span>'
        }, },
      ],
      filterBy: [1, 2, 3, 5],
      rowCallback: function (row, data) {},
    });
	},
}
</script>